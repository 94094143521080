<template>
  <div class="updatePassword" :style="'height: ' + windowHeight + 'px;'">
    <rxNavBar title="更多审批" androidOrIOSFlag="true"></rxNavBar>

    <div class="img_group">
     <img :src="headImg" class="oneImg">
     <div class="setPsd">更多审批</div>
    </div>

    <div class="appointmentDetail">
      <div class="relevvant-detail relevvant-detail-border" @click="clickInfo('reviewOfApplyForHouse')" v-if="checkAuthList(authButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        
        <span class="houseRelevantText">收房</span>
        <van-tag v-if="paramData.collectionRoom > 0" round type="danger" style="margin-left:10px">{{paramData.collectionRoom > 99 ? '99+' : paramData.collectionRoom}}</van-tag>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="clickInfo('allocationAudit')" v-if="checkAuthList(authButtonsLists,'list')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg1" class="detail-img"></div>
        
        <span class="houseRelevantText">配货</span>
        <van-tag v-if="paramData.distribution > 0" round type="danger" style="margin-left:10px">{{paramData.distribution > 99 ? '99+' : paramData.distribution}}</van-tag>
      </div>

      <div class="relevvant-detail relevvant-detail-border" @click="clickInfo('staffEntry')" v-if="checkAuthList(staffEntryAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg2" class="detail-img"></div>

        <span class="houseRelevantText">员工入职审批</span>
        <van-tag v-if="paramData.employmentApprovalCount > 0" round type="danger" style="margin-left:10px">{{paramData.employmentApprovalCount > 99 ? '99+' : paramData.employmentApprovalCount}}</van-tag>
      </div>

      <div class="relevvant-detail relevvant-detail-border" @click="clickInfo('staffDepart')" v-if="checkAuthList(staffDepartAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg3" class="detail-img"></div>

        <span class="houseRelevantText">员工离职审批</span>
        <van-tag v-if="paramData.employmentApprovalCount2 > 0" round type="danger" style="margin-left:10px">{{paramData.employmentApprovalCount2 > 99 ? '99+' : paramData.employmentApprovalCount2}}</van-tag>
      </div>

      <div class="relevvant-detail relevvant-detail-border" @click="clickInfo('probationPlanApproval')" v-if="checkAuthList(probationPlanApprovalAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg4" class="detail-img"></div>

        <span class="houseRelevantText">试岗任务审批</span>
        <van-tag v-if="paramData.mainTrialPlanRecord > 0" round type="danger" style="margin-left:10px">{{paramData.mainTrialPlanRecord > 99 ? '99+' : paramData.mainTrialPlanRecord}}</van-tag>
      </div>

      <div class="relevvant-detail relevvant-detail-border" @click="clickInfo('jobTransfer')" v-if="checkAuthList(jobTransferAuthButtonsList,'list')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg5" class="detail-img"></div>

        <span class="houseRelevantText">岗位调转</span>
        <!-- <van-tag v-if="paramData.mainTrialPlanRecord > 0" round type="danger" style="margin-left:10px">{{paramData.mainTrialPlanRecord > 99 ? '99+' : paramData.mainTrialPlanRecord}}</van-tag> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  NumberKeyboard,
  Toast,
  Calendar,
  DatetimePicker,
  Button,
  Popup,
  Tag
} from 'vant'

import {
  checkAndroid, checkIOS, getStaffId,
  globaluserId,
  money, openInWebview,
  responseUtil
} from "../../../libs/rongxunUtil";
import {
  getStaffFuntionModelList,queryMyPageInit,otherApprovalCount
} from "../../../getData/getData";
import { checkAuthList } from "../../../libs/rongxunUtil";

//调用android关闭页面方法*******begin*********
function backToAndroid(){
  window.himi.closeWindow();
}
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
export default {
  name: 'houseRelevant',
  components: {
    [NavBar.name]: NavBar,
    [NumberKeyboard.name]: NumberKeyboard,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Calendar.name]: Calendar,
    [DatetimePicker.name]: DatetimePicker,
    rxNavBar,
    [Tag.name]: Tag,
    
  },
  data() {
    return {
      userName:'',
      number:'617828',
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
      headImg: require("../../../assets/images/head-quotation-white.png"),
      houseRelevantRenterImg: require("../../../assets/images/mapt.png"),  // 租客
      houseRelevantRenterImg1: require("../../../assets/images/statistics-allocation.png"),  // 租客
      houseRelevantRenterImg2: require("../../../assets/images/staffEntry.png"),  // 入职
      houseRelevantRenterImg3: require("../../../assets/images/staffDepart.png"),  // 离职
      houseRelevantRenterImg4: require("../../../assets/images/probationPlanApproval.png"),  // 离职
      houseRelevantRenterImg5: require("../../../assets/images/transfers.png"),  // 离职
      authButtonsList:[],
      authButtonsLists: [],
      staffEntryAuthButtonsList:[],
      staffDepartAuthButtonsList:[],
      probationPlanApprovalAuthButtonsList:[],
      paramData: {},
      jobTransferAuthButtonsList: []
    }
  },
  mounted() {
    this.getStaffFuntionModelList()
    this.getStaffFuntionModelLists()
    this.queryMyPageInit()
    this.getOtherApprovalCount()
  },
  activated() {
    this.getOtherApprovalCount()
  },
  methods: {
    getOtherApprovalCount() {
      var that = this
        let data = {}
        data.staff_id = getStaffId()
        otherApprovalCount(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
              that.paramData = response.data.data
              console.log(that.paramData,'that.paramData===')
              
            })
        })
    },
    clickInfo:function (path) {
                this.$router.push({
                    name:path,
                    query:{
                        
                    }
                })
            },



   
   
    leftReturn() {
      if(openInWebview()){
        //APP传值*******begin*********
        if(checkAndroid()){
          backToAndroid();
        }else if(checkIOS()){
          window.webkit.messageHandlers.closeWindow.postMessage({});
        }
        //APP传值*******end*********
      }else{
        this.$router.go(-1)
      }
    },
    //权限方法
    getStaffFuntionModelList () {
        var that = this
        let data = {}
        data.staff_id = getStaffId()
        data.menuName = 'receiveRoom_index'
        getStaffFuntionModelList(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
                that.authButtonsList = response.data.data.data
                console.log(that.authButtonsList,'that.authButtonsList===')
            })
        })
    },
    getStaffFuntionModelLists () {
        var that = this
        let data = {}
        data.staff_id = getStaffId()
        data.menuName = 'distributionApplication_index'
        getStaffFuntionModelList(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
                that.authButtonsLists = response.data.data.data
                console.log(that.authButtonsLists,'that.authButtonsList===')
            })
        })
        let initData = {}
        initData.staff_id = getStaffId()
        initData.menuName = 'staffEntry'
        getStaffFuntionModelList(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.staffEntryAuthButtonsList = response.data.data.data
          })
        })
        let queryData = {}
        queryData.staff_id = getStaffId()
        queryData.menuName = 'staffDepart'
        getStaffFuntionModelList(queryData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.staffDepartAuthButtonsList = response.data.data.data
          })
        })
        let data2 = {}
        data2.staff_id = getStaffId()
        data2.menuName = 'probationPlanApproval'
        getStaffFuntionModelList(data2).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.probationPlanApprovalAuthButtonsList = response.data.data.data
          })
        })
        let paramsAata = {
          staff_id: getStaffId(),
          menuName: "jobTransfer"
        }
        getStaffFuntionModelList(paramsAata).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            that.jobTransferAuthButtonsList = response.data.data.data
          })
        })
    },
    
    queryMyPageInit () {
        var that = this
        let data = {}
        data.user_id = globaluserId()
      queryMyPageInit(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
              console.log(response.data.data.userName)
              that.userName = response.data.data.userName
            })
        })
    },
    checkAuthList,
  }
}
</script>

<style scoped>
/*保存按钮不可点击样式*/
.saveButton_Disable{
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}

  .changeStatusStyle {
    height: 2rem;
  }
  .confirmCancelOrdersText {
    margin-left: 35px;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: bold
  }

  .cancelOrdersImg {
    float: left;
    margin: 20px 5px 0 15px;
    width: 15px;
    height: 15px;
  }
  .cnacelOrdersText {
    margin-top: 18px;
    font-size: 15px;
    font-weight: bold
  }

.relevvant-detail {
    position: relative;
  width: 100%;
  height: 1.2rem;

  font-size: 15px;
  line-height: 1.2rem;
}
.relevvant-detail-border {
  border-bottom: #EFEFEF 1px solid;
  border-radius: 8px;
}


.detail-img {
  height:0.4rem;
  margin-left: 20px;
  padding-top: 0.42rem;
}

.houseRelevantText {
  margin-left: 10px;
  font-family: PingFangSC-Semibold;
  font-weight: bold;
}

.updatePassword {
  background: linear-gradient(to bottom, #FFC274 0px, #FF5D3B 100%) repeat scroll 0% 0%;
  height: 18rem;
}
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin-left:10px;
  margin-right:5px;
}
.content-none {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #c7c7c7;
}
.content-have {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #ff5d3b;
}
.roomMesage{
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 5px;
}
.areaDiv{
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 12px;

}
.area {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.devide {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  margin: 0px 7px;
  font-family: PingFangSC-Regular;

}

.roomPhoto {
  width: 151.2px;
  height: 100.08px;
  /*padding: 5px;*/
  margin: 5px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.roomPhotoImage{
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.submitAppointment {
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin: 210px 15px 20px 15px;
  background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  font-size: 16px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  color: white;
  line-height: 45px;
}

.inputDate {
  width: 190px;
  height: 20px;
  /*margin-left: 110px;*/
  outline-style: none;
  border: 0px;
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Regular;
  color: rgba(255, 93, 59, 1);
}

.devide2 {
  width: 1px;
  height: 14px;
  color: rgba(239, 239, 239, 1);
}

.timeText {
  height: 21px;
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Semibold;
}




.timePick {
  width: 18.56px;
  height: 19.24px;
  /*margin-left: 15px;*/
}

.timeDiv {
  width: 92%;
  height: 50px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.spanMonth {
  color: rgba(255, 93, 59, 1);
  font-size: 10px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.money {
  width: 70px;
  height: 24px;
  margin-top: 7px;
  color: rgba(255, 93, 59, 1);
  font-size: 16px;
  font-family: Oswald-Regular;

}

.address {
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  font-family: PingFangSC-Regular;

}
.img_group {
  height: 75px;
  margin: 29px 0px;
}

.setPsd {
  width: 112px;
  height: 40px;
  color: white;
  font-size: 28px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  position: relative;
  font-weight: bold;
  left: 24px;
  bottom: 40px;
  white-space: nowrap;
}

.oneImg {
  width: 58px;
  height: 66px;
}

.appointmentDetail {
  width: 92%;
  margin: 0 auto;
  height: auto;
  /*display: flex;*/
  /*align-items: flex-start;*/
  /*justify-content: space-between;*/
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);

}
.selectTime{
  width: 10rem;
  height: 3rem;
}
.cicleWarn {
    position: absolute;
    top: -5px;
    right: -5px;
}


</style>
